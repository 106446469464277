<template>
  <div id="videoPlayerWrapper" class="player-step video-player-step">
    <div class="confirm-play" v-show="isConfirmationOpen && !isWaiting">
      <div class="lds-dual-ring" v-if="isLoading"></div>
      <button class="submit-btn" v-else @click="startPlayer">
        {{ localization.start_button }}
      </button>
    </div>

    <div class="confirm-play"  v-show="isWaiting">
      <div class="lds-dual-ring" v-if="!modalOpen"></div>
    </div>
      
    <div
      v-show="!isConfirmationOpen && !isWaiting"
      class="video-player-wrapper player-step-component"
      :style="{ opacity: playerOpacity }"
    >
      <div v-if="isAudio">
        <audio id="player">
          <source :src="videoLink" :type="contentType" />
        </audio>
      </div>

      <div
        v-else-if="isExternalVideo"
        id="player"
        ref="player"
        :data-plyr-provider="externalVideoSrc.provider"
        :data-plyr-embed-id="externalVideoSrc.id"
      ></div>
      <div v-else>
        <video id="player" playsinline>
          <source :src="videoLink" :type="contentType" size="576" />
          <source :src="videoLink" :type="contentType" size="720" />
          <source :src="videoLink" :type="contentType" size="1080" />
        </video>
      </div>
    </div>
    <RatingChart
      class="player-step-component"
      v-if="isGraphVisible && isPlayerReady"
    />

    <PlayerControls
      class="player-step-component"
      v-show="isPlayerReady"
      @open-comment-popup="handlePopupOpen('isCommentOpen')"
      @open-quit-popup="handlePopupOpen('isQuitOpen')"
      @toggle-graph="isGraphVisible = !isGraphVisible"
      @flash-player="handleFlash"
    />

    <CommentModal
      v-if="isCommentOpen"
      @close-modal="handlePopupClose()"
    />

    <ErrorModal
      v-if="error"
      @close-error-modal="handleErrorPopupClose()"
    />

    <QuitModal
      v-if="isQuitOpen"
      @close-modal="handlePopupClose()"
      @close-quit-modal="handleQuitPopupClose()"
    />
  </div>
</template>

<script>
import CommentModal from "./CommentModal.vue";
import QuitModal from "./QuitModal.vue";
import ErrorModal from "./ErrorModal.vue";
import RatingChart from "./RatingChart.vue";
import PlayerControls from "./PlayerControls.vue";
import { mapGetters, mapMutations, mapActions, } from "vuex";
import Plyr from "plyr";
import "plyr/dist/plyr.css";

export default {
  name: "PlayerVideo",
  components: { RatingChart, PlayerControls, CommentModal, QuitModal, ErrorModal },
  mounted() {
    this.isGraphVisible = this.isGraphEnabled;
    const player = new Plyr("#player", {
      title: "Testing",
      clickToPlay: false,
      vimeo: { speed: false, playsinline: true, keyboard: false },
      youtube: { playsinline: true },
      autoplay: false,
      keyboard: false,
      tooltips: { controls: false },
      captions: { active: true },
      loop: { active: false },
      settings: ["quality"],
      //debug: true,
      controls: this.playerCtrls,
      listeners: {
        seek() {
          return false;
        },
      },
    });
    player.on("play", this.onPlayerPlay);
    player.on("pause", this.onPlayerPause);
    player.on("ended", this.onPlayerEnded);
    player.on("error", (e) => console.log(e));

    if (this.isExternalVideo) {
      this.externalVideoSrc.provider == "vimeo"
        ? player.once("volumechange", this.playerReadied)
        : player.on("ready", this.playerReadied);
    } else {
      player.once("canplay", this.playerReadied);
    }
    this.player = player;
  },
  data() {
    return {
      player: null,
      // currentPlayerTime: 0,
      isCommentOpen: false,
      isQuitOpen: false,
      isGraphVisible: null,
      playerOpacity: 1,
      isLoading: true,
      isConfirmationOpen: true,
    };
  },
  computed: {
    ...mapGetters("project", [
      "videoLink",
      "isAudio",
      "isExternalVideo",
      "externalVideoSrc",
      "contentType",
      "playerCtrls",
      "isGraphEnabled",
      "localization",
    ]),
    ...mapGetters("videoPlayer", ["isPlayerReady", "playerTime", "error", "isWaiting", "modalOpen"]),
    ...mapGetters("events", ["sinceLastFlash"]),
    isPopupOpen() {
      if (this.isCommentOpen || this.isQuitOpen) return true;
      return false;
    },
  },
  methods: {
    ...mapMutations("videoPlayer", ["SET_VIDEO_PLAYING", "SET_PLAYER_TIME"]),
    ...mapActions("videoPlayer", [
      "togglePlayer",
      "updatePlayerTime",
      "initPlayerData",
    ]),
    ...mapActions("ratings", ["initRatingsData"]),
    ...mapActions("events", ["initEventsData"]),
    onPlayerPlay() {
      this.togglePlayer({ isPlaying: true, isPopupOpen: this.isPopupOpen });
      if (this.isPopupOpen) {
        this.isQuitOpen = false;
        this.isCommentOpen = false;
      }
    },
    onPlayerPause() {
      this.togglePlayer({ isPlaying: false, isPopupOpen: this.isPopupOpen });
    },
    onPlayerEnded() {
      this.$store.commit("videoPlayer/SET_VIDEO_COMPLETED", 1);
      this.$store.dispatch("videoPlayer/endVideo");
    },
    onPlayerTimeUpdate() {
      let currentTime = parseInt(this.player.currentTime);
      if (currentTime >= this.playerTime + 1)
        this.updatePlayerTime(currentTime);
    },
    async playerReadied() {
      let duration = await this.getPlayerDuration();
      this.player.on("timeupdate", this.onPlayerTimeUpdate);
      this.initPlayerData({
        duration,
      });
      this.initRatingsData();
      this.initEventsData();
      this.isLoading = false;
      //this.startPlayer();
    },
    handlePopupOpen(popup) {
      this.$data[popup] = true;
      this.$store.commit("videoPlayer/SET_MODAL_OPEN", true);
      this.player.pause();
    },
    handleErrorPopupClose() {
      this.$store.commit("videoPlayer/SET_MODAL_OPEN", false);
      this.$store.commit("videoPlayer/SET_ERROR_STATUS", false);
    },
    handleQuitPopupClose() {
      this.isQuitOpen = false;
    },
    handlePopupClose() {
      this.$store.commit("videoPlayer/SET_MODAL_OPEN", false);
      this.player.play();
    },
    handleFlash() {
      if (this.sinceLastFlash >= 1 && this.player.playing) {
        this.playerOpacity = 0;
        this.$store.dispatch("events/addFlash");
        setTimeout(() => {
          this.playerOpacity = 1;
        }, 200);
      }
    },
    async getPlayerDuration() {
      if (this.player.embed) return await this.player.embed.getDuration();
      return this.player.duration;
    },
    async startPlayer() {
      this.isLoading = true;
      if (this.playerTime) {
        this.isExternalVideo && this.externalVideoSrc.provider == "vimeo"
          ? await this.player.embed.setCurrentTime(this.playerTime)
          : (this.player.currentTime = this.playerTime);
      }
      this.player.volume = 0.7;
      this.player.muted = false;
      await this.player.play();
      this.isLoading = false;
      this.isConfirmationOpen = false;
      this.$nextTick(() => this.setRatingBarHeight());
    },
    setRatingBarHeight() {
      if (window.innerWidth > 768) return;
      var components = document.getElementsByClassName("player-step-component");
      var height = 0;
      for (var comp of components) height += comp.offsetHeight;
      var ratingBar = document.getElementById("ratingBarWrapper");
      ratingBar.style.height = `${height - 50}px`;
    },
  },
};
</script>

<style></style>
