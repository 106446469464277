<template>
  <div class="modal" @click.self="closeModal">
    <div v-if="isWaiting" class="loading-overlay">
      <div class="lds-dual-ring"></div>
    </div>
    <div class="modal-content confirm-modal">
      <div class="modal-header">
        <div class="title">
          <h5>{{ localization.quit_title }}</h5>
        </div>
        <div class="close-btn" @click.self="closeModal">&#10006;</div>
      </div>
      <div class="modal-body" v-html="localization.quit_text"></div>

      <div class="modal-footer">
        <button class="modal-btn cancel" @click.self="closeModal">
          {{ localization.next_button }}
        </button>
        <button class="modal-btn confirm" @click.self="quitVideo">
          {{ localization.quit_button }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "QuitModal",
  computed: {
    ...mapGetters("project", ["localization"]),
    ...mapGetters("videoPlayer", ["isWaiting"]),
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    async quitVideo() {
      await this.$store.dispatch("videoPlayer/endVideo", true);
      this.$emit("close-quit-modal");
    },
  },
};
</script>

<style></style>
