<template>
  <div class="player-ctrls">
    <div v-for="btn in btnCtrls" :key="btn" class="icon-btn player-ctrl-btn">
      <button @click="btnHandlers[btn]">
        <font-awesome-icon :icon="btn"></font-awesome-icon>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PlayerControls",
  computed: mapGetters("project", ["btnCtrls", "isGraphVisible"]),
  data() {
    return {
      btnHandlers: {
        comment: this.openCommentDialog,
        bolt: this.handleFlash,
        "chart-line": this.toggleGraph,
        times: this.exitProject,
      },
    };
  },
  methods: {
    openCommentDialog() {
      this.$emit("open-comment-popup");
    },
    handleFlash() {
      this.$emit("flash-player");
    },
    toggleGraph() {
      this.$emit("toggle-graph");
    },
    exitProject() {
      this.$emit("open-quit-popup");
    },
  },
};
</script>

<style></style>
