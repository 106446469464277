<template>
  <div class="question-answers multi-choice-answers">
    <label v-for="answer in question.question_answers" :key="answer[0]">
      <input
        type="radio"
        :name="`answer${question.id}[]`"
        :value="answer[0]"
        required
      />
      {{ answer[1] }}
    </label>
  </div>
</template>

<script>
export default {
  name: "RadioInput",
  props: ["question"],
};
</script>

<style></style>
