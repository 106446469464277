<template>
  <div class="step-wrapper">
    <form class="consent-form" @submit="handleConsentSubmit">
      <p v-html="localization.concent_screen"></p>

      <ul class="consent-links">
        <li>
          <a :href="localization.concent_link">
            Consent Information
          </a>
        </li>
        <li>
          <a :href="localization.privacy_link">
            Privacy Policy
          </a>
        </li>
      </ul>

      <label>
        <input type="checkbox" required /> {{ localization.agree_text }}
      </label>

      <button class="submit-btn" type="submit">
        {{ localization.next_button }}
      </button>
    </form>
  </div>
</template>

<script>
import "@/assets/css/steps.css";
import "@/assets/css/consent-step.css";
import { mapGetters } from "vuex";

export default {
  name: "Consent",
  computed: mapGetters("project", ["localization", "survey"]),
  methods: {
    handleConsentSubmit(e) {
      e.preventDefault();
      if (this.survey.isEnabled && this.survey.questions.length)
        this.$store.commit("navigation/SET_ACTIVE_STEP", "SurveyStep");
      else this.$store.commit("navigation/SET_ACTIVE_STEP", "PlayerStep");
    },
  },
};
</script>

<style></style>
