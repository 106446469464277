export default {
  namespaced: true,
  state: {
    activeStep: "",
    isLoading: true,
  },
  mutations: {
    SET_ACTIVE_STEP(state, step) {
      state.activeStep = step;
    },
    TOGGLE_LOADING_OVERLAY(state, loadingStatus) {
      state.isLoading = loadingStatus;
    },
  },
  actions: {
    redirectToFail() {
      console.log("redirecting to fail");
    },
  },
  modules: {},
};
