<template>
  <div class="rating-ctrls">
    <label v-html="info.uppertext"></label>
    <div id="ratingBarWrapper" class="rating-slider-wrapper">
      <div id="slider-vertical" class="rating-slider"></div>
    </div>

    <label v-html="info.lowertext"></label>
  </div>
</template>

<script>
import "@/assets/css/rating-slider.css";
import $ from "jquery";
window.jQuery = $;
import "jquery-ui/ui/widgets/slider";
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/slider.css";
import "jquery-ui/themes/base/theme.css";
require("jquery-ui-touch-punch");
import { mapGetters } from "vuex";

export default {
  name: "RatingSlider",
  mounted() {
    $("#slider-vertical").slider({
      orientation: "vertical",
      range: "min",
      min: 0,
      max: this.maxRating || 100,
      value: this.maxRating ? this.maxRating / 2 : 50,
      slide: (event, ui) => {
        this.$store.commit("ratings/SET_CURRENT_RATING", ui.value);
      },
    });
  },
  data() {
    return {
      rating: "50",
    };
  },
  computed: {
    ...mapGetters("project", ["info", "maxRating"]),
    currentRating: {
      get() {
        return this.$store.state.videoPlayer.rating;
      },
      set(rating) {
        this.$store.commit("ratings/SET_CURRENT_RATING", rating);
      },
    },
  },
};
</script>

<style></style>
