<template>
  <div class="player-step-wrapper">
    <div class="player-content-wrapper">
      <component :is="activePlayerStep" />
    </div>
    <div class="player-rating-wrapper">
      <RatingSlider />
    </div>

    <IpConsent v-if="saveIp" />
  </div>
</template>

<script>
import "@/assets/css/player-step.css";
import PlayerSummary from "./PlayerSummary.vue";
import PlayerInstructions from "./PlayerInstructions.vue";
import PlayerVideo from "./PlayerVideo.vue";
import RatingSlider from "./RatingSlider.vue";
import IpConsent from "./IpConsent.vue";
import { mapGetters } from "vuex";

export default {
  name: "PlayerStep",
  components: {
    PlayerSummary,
    PlayerInstructions,
    PlayerVideo,
    RatingSlider,
    IpConsent,
  },
  created() {
    if (this.info.introduce == "0" && this.info.instruction == "0")
      this.$store.commit("videoPlayer/SET_PLAYER_STEP", "PlayerVideo");
  },
  computed: {
    ...mapGetters("project", ["saveIp", "info"]),
    activePlayerStep() {
      return this.$store.state.videoPlayer.activePlayerStep;
    },
  },
};
</script>

<style></style>
