<template>
  <!-- <div class="rating-chart"></div> -->
  <div class="rating-chart">
    <div id="chartWrapper" ref="chartWrapper" class="charts-wrapper"></div>
    <div class="remove-credit"></div>
  </div>
</template>

<script>
import "@/assets/css/chart.css";
import { getRatingColor } from "../../helpers";
import { mapGetters, mapState } from "vuex";
import CanvasJS from "../../assets/canvasjs.min.js";

export default {
  name: "RatingChart",
  mounted() {
    let chartData = this.ratings.map((rating, i) => {
      return {
        y: parseInt(rating),
        x: i,
        color: getRatingColor(rating, this.maxRating),
        lineColor: getRatingColor(rating, this.maxRating),
        toolTipContent: null,
      };
    });
    this.appendElement(this.$refs.chartWrapper, "div", ["remove-credit"]);

    var chart = new CanvasJS.Chart("chartWrapper", {
      title: {
        text: "",
      },
      backgroundColor: "rgba(0,0,0,0.1)",
      axisY: {
        includeZero: true,
        maximum: this.maxRating,
        minimum: 0,
        gridThickness: 0,
        labelFontColor: "white",
      },
      axisX: {
        minimum: 0,
        maximum: this.playerDuration,
        labelFontColor: "white",
      },
      data: [
        {
          type: "line",
          dataPoints: chartData,
          color: "#4fa245",
        },
      ],
    });

    chart.render();

    this.chartObj = chart;

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type == "ratings/ADD_RATING") {
        let rating = mutation.payload.rating;
        let count = this.chartObj.options.data[0].dataPoints.length;
        this.chartObj.options.data[0].dataPoints.push({
          y: parseInt(rating),
          x: count,
          color: getRatingColor(rating, this.maxRating),
          lineColor: getRatingColor(rating, this.maxRating),
          toolTipContent: null,
        });
        this.chartObj.render();
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
  computed: {
    ...mapState("ratings", ["ratings", "chartLabels", "pointColors"]),
    ...mapState("videoPlayer", ["playerDuration"]),
    ...mapGetters("project", ["maxRating"]),
    chartData() {
      return {
        // labels: this.chartLabels,
        datasets: [
          {
            label: "",
            data: this.ratings,
            fill: false,
            pointBorderColor: this.pointColors,
            pointBackgroundColor: this.pointColors,
          },
        ],
      };
    },
  },
  data() {
    return {
      // myStyles: { height: "100px", width: "100%", color: "#fff" },
    };
  },
  methods: {
    appendElement(parent, childType, classes) {
      let newEl = document.createElement(childType);
      classes.forEach((c) => newEl.classList.add(c));
      parent.appendChild(newEl);
    },
  },
};
</script>

<style></style>
