import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlay,
  faChevronRight,
  faChevronLeft,
  faBolt,
  faTimes,
  faComment,
  faPause,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faPlay,
  faChevronLeft,
  faChevronRight,
  faBolt,
  faTimes,
  faComment,
  faPause,
  faChartLine
);

Vue.component("font-awesome-icon", FontAwesomeIcon);
