import { postRating } from "../api/project.api";
import Papa from 'papaparse';

export default {
  namespaced: true,
  state: {
    // General player state
    activePlayerStep: "PlayerSummary",
    recordingDate: "",
    playerTime: 0,
    playerDuration: 0,
    isPlayerReady: false,
    completed: 0,
    videoPlaying: false,
    isSubmitting: false,
    lastSubmitTime: -1,
    error: false,
    isWaiting: false,
    modalOpen: false
  },
  getters: {
    playerTime: (state) => parseInt(state.playerTime),
    playerDuration: (state) => parseInt(state.playerDuration),
    isPlayerReady: (state) => state.isPlayerReady,
    error: (state) => state.error,
    isWaiting: (state) => state.isWaiting,
    sinceLastSubmit: (state) =>
      parseInt(state.playerTime) - parseInt(state.lastSubmitTime),
    modalOpen: (state) => state.modalOpen
  },
  mutations: {
    SET_VIDEO_PLAYING(state, videoState) {
      state.videoPlaying = videoState;
    },
    SET_SUBMITTING_DATA(state, payload) {
      state.isSubmitting = payload.isSubmitting;
      state.lastSubmitTime = payload.lastSubmitTime || state.lastSubmitTime;
    },
    SET_PLAYER_STEP(state, step) {
      state.activePlayerStep = step;
    },
    SET_PLAYER_TIME(state, time) {
      state.playerTime = time;
    },
    INIT_PLAYER_DATA(state, payload) {
      state.isPlayerReady = payload.isReady;
      state.playerDuration = payload.duration;
      state.recordingDate = payload.recordingDate;
      state.playerTime = payload.playerTime;
    },
    SET_VIDEO_COMPLETED(state, isCompleted) {
      state.completed = isCompleted;
    },
    SET_MODAL_OPEN(state, modalOpen) {
      state.modalOpen = modalOpen;
    },
    SET_ERROR_STATUS(state, errorStatus) {
      state.error = errorStatus;
    },
    SET_WAITING_STATUS(state, waitingStatus) {
      state.isWaiting = waitingStatus;
    }
  },
  actions: {
    updatePlayerTime({ commit, getters, dispatch }, time) {
      commit("SET_PLAYER_TIME", time);
      dispatch("ratings/addRating", null, { root: true });

      // if (getters.sinceLastSubmit >= 5) dispatch("submitRating");
      // if (getters.sinceLastSubmit >= 5) commit("SET_WAITING_STATUS", true);
    },
    async togglePlayer({ commit, getters }, payload) {
      commit("SET_VIDEO_PLAYING", payload.isPlaying);
      if (payload.isPlaying && !payload.isPopupOpen) {
        let date = new Date();
        let time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        commit(
          "events/ADD_EVENT",
          {
            event: `Abspielen (${time})`,
            eventTime: getters.playerTime,
          },
          { root: true }
        );
      } else if (!payload.isPlaying && !payload.isPopupOpen)
        commit(
          "events/ADD_EVENT",
          { event: "Pause", eventTime: getters.playerTime },
          { root: true }
        );
    },
    initPlayerData({ commit, rootGetters }, payload) {
      let hasUserData = rootGetters["project/hasUserData"];
      let userData = rootGetters["project/userData"];
      let playerTime = rootGetters["project/lastSecondReached"];
      let recordingDate = hasUserData
        ? userData["Aufnahmedatum"]
        : generateRecordingDate();
      commit("INIT_PLAYER_DATA", {
        isReady: true,
        recordingDate,
        playerTime,
        duration: parseInt(payload.duration),
      });
    },
    async endVideo({ commit, dispatch, rootGetters, state }) {
      try {
        await dispatch("submitRating", true);
      } catch (e) {
        console.log("endvideo:" + e);
      } finally {
        let enableCSVExport = rootGetters["project/enableCSVExport"];
        if(enableCSVExport) {
          await dispatch("saveData2CSV");
        }
        if(state.error) {
          console.log('error');
        }

        else {
          dispatch("goFinalStep");
        }
      }
    },
    async resubmit({ commit, dispatch, rootGetters, state }) {
      try {
        await dispatch("submitRating", true);
      } catch (e) {
        console.log("endvideo:" + e);
      } finally {
        if(state.error) {
          console.log('error');
        }
        else {
          dispatch("goFinalStep");
        }
      }
    },
    goFinalStep({ commit, rootGetters }) {
      let redirectLink = rootGetters["project/redirectLink"];
      if (redirectLink) {
          window.location.href = redirectLink.replace('%pid%', rootGetters["project/personId"]);
      }
      else commit("navigation/SET_ACTIVE_STEP", "finishStep", { root: true });
    },
    submitRating({ state, commit, rootGetters }, isEndSubmitCall = false) {
      if (state.isSubmitting && !isEndSubmitCall) {
        return;
      }

      return new Promise((resolve, reject) => {
        commit("SET_WAITING_STATUS", true);
        commit("SET_SUBMITTING_DATA", {
          isSubmitting: true,
          lastSubmitTime: state.playerTime,
        });
        let formData = new FormData();
        let data = {
          Aufnahmedatum: state.recordingDate,
          personId: rootGetters["project/personId"],
          projectId: rootGetters["project/projectId"],
          saveip: rootGetters["project/saveIp"],
          rtrdata: rootGetters["ratings/ratings"],
          rtrinfo: rootGetters["events/events"],
          seconds: state.playerDuration,
          completed: state.completed,
          finished: state.playerTime,
        };
        for (var x in data) formData.append(x, data[x]);
        postRating(formData)
          .then((res) => {commit("SET_WAITING_STATUS", false); commit("SET_ERROR_STATUS", false);  return resolve(res); })
          .catch((err) => {console.log("error: " + JSON.stringify(err)); commit("SET_ERROR_STATUS", true); commit("SET_WAITING_STATUS", false);  reject(err)})
          .finally(() => {
            commit("SET_SUBMITTING_DATA", { isSubmitting: false });
        });
      });
    },
    saveData2TXT({ state, commit, rootGetters }) {
      let data = {
        Aufnahmedatum: state.recordingDate,
        personId: rootGetters["project/personId"],
        projectId: rootGetters["project/projectId"],
        rtrdata: rootGetters["ratings/ratings"],
        rtrinfo: rootGetters["events/events"],
        seconds: state.playerDuration,
        completed: state.completed,
        finished: state.playerTime,
      };
      let saveData = JSON.stringify(data);
      const blob = new Blob([saveData], { type: 'text/plain' });
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `rtr_proj${data.projectId}_${data.Aufnahmedatum}.txt`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    saveData2CSV({ state, commit, rootGetters }) {
      let questions = rootGetters["project/question"];
      let answers = rootGetters["project/answer"];
      let playerDuration = state.playerDuration;
      let personId = rootGetters["project/personId"];
      let projectId = rootGetters["project/projectId"];
      let Aufnahmedatum = state.recordingDate;

      const zeitArray =  Array.from({ length: playerDuration }, (_, index) => index);
      let data = {
        Zeit: zeitArray, 
      };
      data["Data (" + personId + ")"] = rootGetters["ratings/ratings"].split("|");
      const events = rootGetters["events/events"].split('\r\n');
      const eventArray = new Array(playerDuration).fill(null);

      events.forEach(event => {
        const match = event.match(/\[(\d+)\] (.+)/);
        if (match) {
          const index = parseInt(match[1]);
          if(index < playerDuration) {
            eventArray[index] = match[2];
          }
        }
      });
      data["Info (" + personId + ")"] = eventArray;

      for(let i = 0; i < questions.length; i ++) {
        data[questions[i] + " ("+ personId +")"] = Array(playerDuration).fill(answers[i]);
      }
    
      const headers = Object.keys(data);
      
      const rowCount = Math.max(...Object.values(data).map(arr => arr.length));
      
      const csvdata = Array.from({ length: rowCount }).map((_, i) => {
        return headers.map(header => data[header][i] ?? '');
      });
      
      const csv = Papa.unparse({
        fields: headers,
        data: csvdata
      });
      
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `rtr_proj${projectId}_${Aufnahmedatum}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  modules: {},
};

const generateRecordingDate = () => {
  let date = new Date();
  let recordingDate = `${date.getFullYear()}-${date.getMonth() +
    1}-${date.getDate()}`;
  let recordingTime = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  return `${recordingDate}\n${recordingTime}`;
};
