<template>
  <div class="modal" @click.self="closeModal">
    <div class="modal-content confirm-modal">
      <div class="modal-header">
        <div class="title">
          <h5>{{ localization.comment_title }}</h5>
        </div>
        <div class="close-btn" @click.self="closeModal">&#10006;</div>
      </div>

      <div class="modal-body">
        <textarea v-model="text" name="" id="" cols="30" rows="10"></textarea>
      </div>

      <div class="modal-footer">
        <button class="modal-btn cancel" @click.self="closeModal">
          {{ localization.comment_exit }}
        </button>
        <button @click="saveComment" class="modal-btn confirm">
          {{ localization.comment_button }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CommentModal",
  computed: mapGetters("project", ["localization"]),
  data() {
    return {
      text: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
    saveComment() {
      if (!this.text) {
        this.$emit("close-modal");
        return;
      }
      this.$store.dispatch("events/addComment", this.text);
      this.$emit("close-modal");
    },
  },
};
</script>

<style></style>
