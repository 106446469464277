import Vue from "vue";
import Vuex from "vuex";
import navigation from "./navigation";
import project from "./project";
import videoPlayer from "./video-player";
import events from "./video-events";
import ratings from "./video-ratings";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    navigation,
    project,
    videoPlayer,
    events,
    ratings,
  },
});
