<template>
  <div id="app">
    <div v-if="isLoading" class="loading-overlay">
      <div class="lds-dual-ring"></div>
    </div>
    <component :is="activeComponent" />
  </div>
</template>

<script>
import PlayerStep from "@/components/PlayerStep/PlayerStep.vue";
import ConsentStep from "@/components/ConsentStep.vue";
import SurveyStep from "@/components/SurveyStep.vue";
import FinishStep from "@/components/FinishStep.vue";
import RedirectStep from "@/components/RedirectStep.vue";
import { validateUrlParam, getUrlParam } from "./helpers";

export default {
  name: "App",
  components: {
    ConsentStep,
    SurveyStep,
    PlayerStep,
    FinishStep,
    RedirectStep,
  },
  computed: {
    activeComponent() {
      return this.$store.state.navigation.activeStep;
    },
    isLoading() {
      return this.$store.state.navigation.isLoading;
    },
  },
  created() {
    console.log("app is created");
    if (!validateUrlParam("id")) {
      this.$store.dispatch("navigation/redirectToFail");
      return;
    }

    this.$store.dispatch("project/loadProject", getUrlParam("id"));
  },
};
</script>

<style>
@import "./assets/css/base.css";
</style>
