<template>
  <div class="player-instructions">
    <h4 v-html="soundTitle"></h4>

    <div class="sound-test">
      <div v-html="soundExplain"></div>

      <div class="play-btn">
        <div class="icon-btn">
          <audio ref="audio" loop src="@/assets/audio.mp3"></audio>
          <button @click="playAudio">
            <font-awesome-icon
              :icon="isAudioPlaying ? 'pause' : 'play'"
            ></font-awesome-icon>
          </button>
        </div>
      </div>
    </div>

    <h4><b>Control Elements</b></h4>

    <div class="instructions">
      <InstructionLine
        v-for="instruction in instructions"
        :instruction="instruction"
        :key="instruction.icon"
      />
    </div>

    <div class="instructions-ctrls">
      <div class="icon-btn">
        Back
        <button @click="goToStep('PlayerSummary')">
          <font-awesome-icon icon="chevron-left"></font-awesome-icon>
        </button>
      </div>
      <div class="icon-btn">
        {{ localization.start_button }}
        <button @click="goToStep('PlayerVideo')">
          <font-awesome-icon icon="chevron-right"></font-awesome-icon>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import InstructionLine from "./InstructionLine";
import { mapGetters } from "vuex";

export default {
  name: "PlayerInstructions",
  components: { InstructionLine },
  created() {
    if (this.info.instruction == "0")
      this.$store.commit("videoPlayer/SET_PLAYER_STEP", "PlayerVideo");
  },
  data() {
    return {
      isAudioPlaying: "",
    };
  },
  computed: {
    ...mapGetters("project", ["localization", "info"]),
    soundTitle() {
      return this.localization.sound_title;
    },
    soundExplain() {
      return this.localization.sound_explain;
    },
    instructions() {
      let instructions = [];
      let actionNotSuppresed = this.info.suppressaction == "0" ? true : false;

      if (this.info.explaintext1 && actionNotSuppresed)
        instructions.push({ text: this.info.explaintext1, icon: "bolt" });

      if (this.info.explaintext2 && actionNotSuppresed)
        instructions.push({ text: this.info.explaintext2, icon: "comment" });

      if (actionNotSuppresed)
        instructions.push({
          text: this.localization.explain_text3,
          icon: "pause",
        });

      instructions.push({
        text: this.localization.explain_text4,
        icon: "times",
      });

      return instructions;
    },
  },
  methods: {
    goToStep(step) {
      this.$store.commit("videoPlayer/SET_PLAYER_STEP", step);
    },
    playAudio() {
      if (this.$refs.audio.paused) this.$refs.audio.play();
      else this.$refs.audio.pause();

      this.isAudioPlaying = !this.$refs.audio.paused;
    },
  },
};
</script>

<style></style>
