<template>
  <div class="question-answers">
    <select :name="`answer${question.id}`" id="" required>
      <option value="">{{ localization.select_answer_text }}</option>
      <option
        v-for="answer in question.question_answers"
        :key="answer[0]"
        :value="answer[0]"
      >
        {{ answer[1] }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SelectInput",
  props: ["question"],
  computed: mapGetters("project", ["localization"]),
};
</script>

<style></style>
