import {
  fetchProjectValidity,
  fetchProjectData,
  postSurveyAnswers,
  fetchUserData,
} from "../api/project.api";
import { validateUrlParam, getUrlParam } from "../helpers";

export default {
  namespaced: true,
  state: {
    info: {},
    userData: {},
    answer: []
  },
  mutations: {
    SET_PROJECT_DATA(state, payload) {
      state.info = payload.projectData;
    },
    SET_USER_DATA(state, payload) {
      state.userData = payload;
    },
    SET_ANSWER(state, answer) {
      state.answer = answer;
    },
    TOGGLE_GRAPH(state, isVisible) {
      state.info.linegraph = isVisible;
    },
  },
  getters: {
    localization: (state) => state.info.localization,
    info: (state) => state.info,
    personId: (state, getters) => {
      if (validateUrlParam(getters.pidParam))
        return getUrlParam(getters.pidParam);
      return state.info.personId;
    },
    projectId: (state) => state.info.id,
    hasUserData: (state) => Object.keys(state.userData).length,
    userData: (state) => state.userData,
    lastSecondReached: (state, getters) => {
      if (getters.hasUserData)
        return state.userData["RTR-Data"].split("|").length;
      return 0;
    },
    survey: (state) => {
      return {
        isEnabled: parseInt(state.info.QuestionOption),
        questions: state.info.questions,
      };
    },
    answerList: (state) => {
      const questions = state.info.questions;
      let answerList = {};
      for (let i = 0; i < questions.length; i ++) {
        const question_answers = questions[i].question_answers;
        for(let j = 0; j < question_answers.length; j ++) {
          answerList[question_answers[j][0]] = question_answers[j][1];
        }
      }
      return answerList;
    },
    pidParam: (state) => state.info.PersonID,
    isGraphEnabled: (state) => parseInt(state.info.linegraph),
    videoLink: (state) => state.info.link,
    contentType: (state) => state.info.contentType,
    isExternalVideo: (state) => {
      if (state.info.isYoutube || state.info.isVimeo) return true;
      return false;
    },
    isAudio: (state) => state.info.isAudio,
    externalVideoSrc: (state) => {
      if (state.info.isYoutube)
        return { provider: "youtube", id: state.info.isYoutube };
      if (state.info.isVimeo)
        return { provider: "vimeo", id: state.info.isVimeo };
      return { provider: "", id: "" };
    },
    playerCtrls: (state) => {
      let ctrls = [];
      state.info.suppressaction == "0" ? ctrls.push("play") : null;
      state.info.progress == "1"
        ? ctrls.push("progress", "current-time")
        : null;
      state.info.mute == "1" ? ctrls.push("settings", "volume") : null;
      state.info.mute == "1" ? ctrls.push("mute") : null;
      return ctrls;
    },
    enableCSVExport: (state) => {
      return state.info.csv == "1" ? true : false;
    },
    btnCtrls: (state) => {
      let ctrls = [];
      state.info.suppressaction == "0" ? ctrls.push("comment", "bolt") : null;
      state.info.linegraph == "1" ? ctrls.push("chart-line") : null;
      ctrls.push("times");
      return ctrls;
    },
    redirectLink: (state) => state.info.redirectlink,
    saveIp: (state) => parseInt(state.info.save_ip),
    maxRating: (state) => parseInt(state.info.slidersteps),
    answer: (state) => state.answer,
    question: (state) => {
      return state.info.questions.map(question => question.question_title);
    }
  },
  actions: {
    async loadProject({ commit, getters, state }, id) {
      try {
        await fetchProjectValidity(id);
        let response = await fetchProjectData(id);

        commit("SET_PROJECT_DATA", { projectData: response.data });
        if (getters.pidParam && validateUrlParam(getters.pidParam)) {
          let userData = await fetchUserData(
            getters.projectId,
            getUrlParam(getters.pidParam)
          );
          commit("SET_USER_DATA", userData.data);
        }
        if (state.userData.completed == "1") {
          commit("navigation/SET_ACTIVE_STEP", "finishStep", { root: true });
          return;
        }
        if (getters.hasUserData) {
          commit("navigation/SET_ACTIVE_STEP", "redirectStep", { root: true });
          return;
        }
        commit("navigation/SET_ACTIVE_STEP", "consentStep", { root: true });
      } catch (err) {
        console.log(err);
      } finally {
        commit("navigation/TOGGLE_LOADING_OVERLAY", false, { root: true });
      }
    },
    async submitAnswers({ commit, getters }, formData) {
      try {
        commit("navigation/TOGGLE_LOADING_OVERLAY", true, { root: true });
        let answerList = getters.answerList;
        let queryNumber = getters.question.length;
        let answers = Array(queryNumber).fill("");
        for(let entry of formData.entries()) {
          const key = entry[0];
          const value = entry[1];
          if(key.startsWith("answer"))  {
            const index = parseInt(value.match(/q(\d+)/)[1]);
            if(answers[index - 1]) {
              answers[index - 1] += "," + answerList[value];
            } else {
              answers[index - 1] += answerList[value];
            }
          }
        }
        commit("SET_ANSWER", answers);
        formData.append("personId", getters.personId);
        await postSurveyAnswers(formData);
        commit("navigation/TOGGLE_LOADING_OVERLAY", false, { root: true });
        commit("navigation/SET_ACTIVE_STEP", "playerStep", { root: true });
      } catch (err) {
        console.log(err);
      }
    },
  },
  modules: {},
};
