<template>
  <div class="player-summary">
    <div v-html="summaryText"></div>

    <div class="icon-btn">
      {{ continueText }}
      <button @click="goToStep('PlayerInstructions')">
        <font-awesome-icon icon="play"></font-awesome-icon>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PlayerSummary",
  created() {
    if (this.info.introduce == "0")
      this.$store.commit("videoPlayer/SET_PLAYER_STEP", "PlayerInstructions");
  },
  computed: {
    ...mapGetters("project", ["localization", "survey", "info"]),
    summaryText() {
      if (this.survey.isEnabled)
        return `${this.info.explaintitle}<br>${this.info.explaintext}`;
      return this.info.explaintext;
    },
    continueText() {
      return this.localization.next_button;
    },
  },
  methods: {
    goToStep(step) {
      this.$store.commit("videoPlayer/SET_PLAYER_STEP", step);
    },
  },
};
</script>

<style></style>
