<template>
  <div class="step-wrapper redirect-step">
    <div class="welcome-text">{{ welcomeText }}</div>
    <select v-model="action">
      <option value="continue">{{ localization.continue_agree }}</option>
      <option value="startover">{{ localization.continue_disagree }}</option>
    </select>
    <button @click="handleSubmit" class="submit-btn">
      {{ localization.next_button }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import "@/assets/css/steps.css";
import "@/assets/css/redirect-step.css";

export default {
  name: "Redirect",
  computed: {
    ...mapGetters("project", ["personId", "localization"]),
    welcomeText() {
      return this.localization.continue_text.replace(
        "{PERSON_ID}",
        this.personId
      );
    },
  },
  data() {
    return {
      action: "continue",
      availableActions: {
        continue: this.handleContinue,
        startover: this.handleStartover,
      },
    };
  },
  methods: {
    handleSubmit() {
      if (!this.availableActions[this.action]) return;
      this.availableActions[this.action]();
    },
    handleContinue() {
      this.$store.commit("videoPlayer/SET_PLAYER_STEP", "PlayerVideo");
      this.$store.commit("navigation/SET_ACTIVE_STEP", "playerStep");
    },
    handleStartover() {
      this.$store.commit("project/SET_USER_DATA", {});
      this.$store.commit("navigation/SET_ACTIVE_STEP", "playerStep");
    },
  },
};
</script>

<style></style>
