import { getRatingColor } from "../helpers";

export default {
  namespaced: true,
  state: {
    ratings: [],
    chartLabels: [],
    pointColors: [],
    lastRatingTime: 0,
    lastRating: "50",
    currentRating: "50",
  },
  getters: {
    ratings: (state) => state.ratings.join("|"),
    sinceLastRate: (state, getters, rootState, rootGetters) =>
      parseInt(rootGetters["videoPlayer/playerTime"]) -
      parseInt(state.lastRatingTime),
  },
  mutations: {
    ADD_RATING(state, payload) {
      state.ratings.push(payload.rating);
      state.pointColors.push(payload.color);
    },
    SET_CURRENT_RATING(state, rating) {
      state.currentRating = rating;
    },
    UPDATE_LAST_RATE_DATA(state, payload) {
      state.lastRatingTime = payload.lastRatingTime;
      state.lastRating = payload.lastRating;
    },
    INIT_RATINGS_DATA(state, payload) {
      state.currentRating = payload.currentRating;
      state.lastRatingTime = payload.lastRatingTime;
      state.lastRating = payload.lastRating;
      state.ratings = payload.ratings;
      state.chartLabels = payload.chartLabels;
      state.pointColors = payload.pointColors;
    },
  },
  actions: {
    initRatingsData({ commit, rootGetters }) {
      let hasUserData = rootGetters["project/hasUserData"];
      let userData = rootGetters["project/userData"];
      let maxRating = rootGetters["project/maxRating"];
      let duration = rootGetters["videoPlayer/playerDuration"];
      let chartLabels = Array(duration - 1).fill("");
      let ratings = hasUserData ? userData["RTR-Data"].split("|") : [];
      let currentRating = ratings.length
        ? ratings[ratings.length - 1]
        : parseInt(maxRating / 2);
      let lastRating = currentRating;
      let lastRatingTime = rootGetters["project/lastSecondReached"];
      let pointColors = ratings.map((rating) =>
        getRatingColor(rating, maxRating)
      );

      commit("INIT_RATINGS_DATA", {
        currentRating,
        lastRating,
        lastRatingTime,
        ratings,
        chartLabels,
        pointColors,
      });
    },
    addRating({ state, commit, getters, rootGetters }) {
      commit("ADD_RATING", {
        rating: state.currentRating,
        color: getRatingColor(
          state.currentRating,
          rootGetters["project/maxRating"]
        ),
      });

      if (getters.sinceLastRate > 10 && state.currentRating != state.lastRating)
        commit(
          "events/ADD_EVENT",
          {
            eventTime: rootGetters["videoPlayer/playerTime"],
            event: `Inactiv von ${state.lastRatingTime} bis ${rootGetters["videoPlayer/playerTime"]} = ${getters.sinceLastRate} Sekunden`,
          },
          { root: true }
        );

      if (state.currentRating != state.lastRating)
        commit("UPDATE_LAST_RATE_DATA", {
          lastRating: state.currentRating,
          lastRatingTime: rootGetters["videoPlayer/playerTime"],
        });
    },
    handleInactivity() {},
  },
  modules: {},
};
