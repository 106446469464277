<template>
  <div class="ip-consent">
    <div class="text">
      {{ localization.ip_warning }}
    </div>
    <div class="close-btn" @click="closeWarning">
      &#10006;
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "IpConsent",
  computed: mapGetters("project", ["localization"]),
  methods: {
    closeWarning() {
      this.$destroy();
      this.$el.parentNode.removeChild(this.$el);
    },
  },
};
</script>

<style></style>
