<template>
  <div class="step-wrapper finish-step">
    <h2>{{ stepText.title }}</h2>
    <p>{{ stepText.text }}</p>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import "@/assets/css/steps.css";
import "@/assets/css/finish-step.css";

export default {
  name: "Finish",
  computed: {
    ...mapState("project", ["userData"]),
    ...mapGetters("project", ["localization"]),
    stepText() {
      if (this.userData.completed == "1") {
        return {
          title: this.localization.thank_title,
          text: this.localization.thank_text,
        };
      }
      return {
        title: this.localization.complete_title,
        text: this.localization.complete_text,
      };
    },
  },
  methods: {},
};
</script>

<style></style>
