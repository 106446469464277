const validateUrlParam = (param) => {
  let urlParams = window.location.search;
  if (!urlParams) return false;
  let parsedParams = new URLSearchParams(urlParams);
  if (!parsedParams.get(param)) return false;
  return true;
};

const getUrlParam = (param) => {
  let urlParams = window.location.search;
  let parsedParams = new URLSearchParams(urlParams);
  return parsedParams.get(param);
};

const getRatingColor = (rating, maxRating) => {
  let color = "";
  rating > maxRating / 3 && rating < (2 * maxRating) / 3
    ? (color = "#DFDFDF")
    : rating < maxRating / 3
    ? (color = "#EF0504")
    : (color = "#39C326");
  return color;
};

export { validateUrlParam, getUrlParam, getRatingColor };
