<template>
  <div class="instruction-line">
    <div class="icon-btn instruction-icon">
      <button>
        <font-awesome-icon :icon="instruction.icon"></font-awesome-icon>
      </button>
    </div>
    <div v-html="instruction.text"></div>
  </div>
</template>

<script>
export default {
  name: "InstructionLine",
  props: { instruction: Object },
};
</script>

<style></style>
