import httpClient from "./httpClient";

const fetchProjectValidity = (id) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get("validateproject.php", { params: { id } })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject(false);
      });
  });
};

const fetchProjectData = (id) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get("getproject.php", { params: { id: id } })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => reject(err));
  });
};

const postSurveyAnswers = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post("saveanswers.php", data)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const postRating = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post("save.php", data, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

const fetchUserData = (projectId, personId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get("getpersoninfo.php", {
        params: {
          person_id: personId,
          project_id: projectId,
        },
      })
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};

export {
  fetchProjectValidity,
  fetchProjectData,
  postSurveyAnswers,
  postRating,
  fetchUserData,
};
