export default {
  namespaced: true,
  state: {
    events: [],
    lastFlashTime: -1,
  },
  getters: {
    events: (state) => state.events.join("\r\n"),
    sinceLastFlash: (state, getters, rootState, rootGetters) =>
      parseInt(rootGetters["videoPlayer/playerTime"]) -
      parseInt(state.lastFlashTime),
  },
  mutations: {
    ADD_EVENT(state, payload) {
      state.events.push(`[${payload.eventTime}] ${payload.event}`);
    },
    UPDATE_LAST_FLASH_TIME(state, lastFlashTime) {
      state.lastFlashTime = lastFlashTime;
    },
    INIT_EVENT_DATA(state, payload) {
      state.events = payload.events;
      state.lastFlashTime = payload.lastFlashTime;
    },
  },
  actions: {
    initEventsData({ commit, rootGetters }) {
      let hasUserData = rootGetters["project/hasUserData"];
      let userData = rootGetters["project/userData"];
      let events = hasUserData ? userData["RTR-Info"].split("\r\n") : [];
      let lastFlashTime = rootGetters["project/lastSecondReached"];

      commit("INIT_EVENT_DATA", {
        events,
        lastFlashTime,
      });
    },
    addComment({ commit, rootGetters }, comment) {
      commit("ADD_EVENT", {
        event: `Kommentar: ${comment}`,
        eventTime: rootGetters["videoPlayer/playerTime"],
      });
    },
    addFlash({ commit, rootGetters }) {
      commit("UPDATE_LAST_FLASH_TIME", rootGetters["videoPlayer/playerTime"]);
      commit("ADD_EVENT", {
        event: "Umschalten",
        eventTime: rootGetters["videoPlayer/playerTime"],
      });
    },
  },
  modules: {},
};
