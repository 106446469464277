<template>
  <div class="step-wrapper">
    <form class="survey-form" @submit="handleSurveySubmit">
      <h5 class="survey-title" v-html="localization.survey_title"></h5>
      <p class="survey-description">
        {{ localization.survey_text }}
      </p>

      <div
        class="survey-question"
        v-for="(q, i) in survey.questions"
        :key="q.id"
      >
        <input type="hidden" :value="q.id" name="question_id[]" />
        <div class="question-title">{{ i + 1 }}. {{ q.question_title }}</div>
        <component :is="getQuestionType(q.question_type)" :question="q" />
      </div>

      <input class="submit-btn" type="submit" />
    </form>
  </div>
</template>

<script>
import "@/assets/css/steps.css";
import "@/assets/css/survey-step.css";
import { mapGetters } from "vuex";
import RadioInput from "./common/RadioInput.vue";
import SelectInput from "./common/SelectInput.vue";
import CheckboxInput from "./common/CheckboxInput.vue";

export default {
  name: "Survey",
  components: { CheckboxInput, SelectInput, RadioInput },
  computed: mapGetters("project", ["localization", "survey"]),
  methods: {
    handleSurveySubmit(e) {
      e.preventDefault();
      let formData = new FormData(e.target);
      this.$store.dispatch("project/submitAnswers", formData);
    },
    getQuestionType(type) {
      if (type == 0) return "SelectInput";
      if (type == 1) return "CheckboxInput";
      return "RadioInput";
    },
  },
};
</script>

<style></style>
